import React, { Component } from 'react';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    Link
  } from "react-router-dom";
  import { ReactComponent as Insta } from '../assets/svg/insta.svg';
  import { ReactComponent as Fb } from '../assets/svg/fb.svg';

class Footer extends Component {
  
  render() {

    return (

        <div className="footer">

            <div className="wrapper">

                <div className="footer-content">

                    <Link to="/" className="footer-content-logo">

                        <Logo />

                    </Link>

                    <div className="footer-content-about">

                        <div className="footer-content-about-item">

                            <span>Tineret Bujac</span>

                            <footer>Toate drepturile rezervate &copy; 2022-2023</footer>

                        </div>

                        <div className="footer-content-about-item">

                            <Link to="/implica-te" className="linkLine">Implică-te</Link>

                            <Link to="/intrebari-si-propuneri" className="linkLine">Adaugă o întrebare</Link>
                                
                        </div>

                    </div>

                </div>

                <div className="footer-links">

                    <a href="https://www.instagram.com/tineretbujac/" target="_blank" rel="noopenner noreferrer"><Insta /></a>

                    <a href="https://www.facebook.com/groups/965109080215342" target="_blank" rel="noopenner noreferrer"><Fb /></a>

                </div>

            </div>

        </div>
        
    )
  }
}

export default Footer;

import React from 'react';
import $ from 'jquery';

function Hero(props) {

    $( 'body' ).addClass( 'bghero' );

    return (

        <div className="heropage">

            <div className={'wrapper ' + props.side}>

                <img src={props.img} alt={props.title} />

                <div className="heropage-con">

                    <h1 className="title50">{props.title}</h1>

                    <p className="p16">{props.description}</p>

                </div>

            </div> 

        </div>
    );
}

export default Hero;
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import {
    // BrowserRouter as Router,
    Link
} from "react-router-dom";
import Hero from '../components/Hero-evt';
import heroImg from '../assets/img/evt-one.jpeg';

const heroTitle = 'Evenimentele noastre';
const heroDescription = 'Pentru că suntem o comunitate activă, avem diverse întâlniri, activități sociale sau sportive prin care lucrăm la unitatea dintre noi. Ne-ar face plăcere să faci și tu parte din următoarele întâlniri, dar până atunci poți urmări parte din întălnirile noastre.';

function Events() {

    const [formData, setFormData] = useState({
        events: [],
        categories: []
    });

    const {events, categories} = formData;

    const getEvents = (category_id) => {

        axios.get(`${process.env.REACT_APP_API_URL}events/category/${category_id}`)
        .then(function (response) {

            const {events} = response.data;

            setFormData (
                {
                    ...formData, events

                }
            )
        })
        .catch(function (error) {

            console.log(error);

        })

    }

    useEffect(() => {
        
        $(document).on('click', '.filtersbtns-i', function() {

            $( '.filtersbtns-i' ).removeClass( 'active' );

            $(this).addClass('active');

        });

        axios.get(`${process.env.REACT_APP_API_URL}events`)
        .then(function (response) {

            const {formData, events, categories} = response.data;

            setFormData (
                {
                    ...formData, events, categories

                }
            )


        })
        .catch(function (error) {

            console.log(error);

        })

    }, []);

    const [category, setCategory] = useState();

    return (

        <div className="events" data-used={[category, setCategory]}>

            <Hero title={heroTitle} description={heroDescription} img={heroImg} />

            <div className="filtersbtns wrapper">

                <button className="filtersbtns-i active" onClick={() => { getEvents( 'all' ) }} data-categories="default">Toate Categoriile</button>

                {Object.keys(categories).map((qs, i) => (

                    <button onClick={() => { getEvents( categories[qs]._id ) }} className="filtersbtns-i" key={i} data-categories={categories[qs].name}>{categories[qs].name}</button>

                ))}

            </div>

            <div className="events-items wrapper">

                {Object.keys(events).map((qs, i) => (

                    <div className="events-items-i eventdesign" key={i} data-personal-cat={events[qs].categoryName}>

                        <span className="label-date">{events[qs].date}</span>

                        <img src={events[qs].image} alt={events[qs].title} />

                        <h3 className="title40">{events[qs].title}</h3>

                        <p className="p14">{events[qs].description}</p>

                        <Link to={`evenimente/${events[qs].slug}`} className="simple-link">Vezi detalii</Link>

                    </div>

                ))}

            </div>

        </div>
    );
}

export default Events;
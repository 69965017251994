import React, {createContext, useState} from 'react';
import socketio from "socket.io-client";
import {getToken} from "./auth";

export const SocketContext = createContext({
    token: '',
    socket_connection: ''
});

export const SocketProvider = ({children}) => {
    const bearerToken = getToken()

    function verifyToken() {
        if (bearerToken !== undefined) {
            return bearerToken
        } else {
            return undefined
        }
    }

    const [socket, setSocket] = useState(undefined)

    const socket_connection = (token) => {
        let get_conn_socket = socketio.connect(`${process.env.REACT_APP_SOCKET_URL}`, {
            extraHeaders: {
                "authorization": "Bearer " + token,
                "isweb": 'true'
            }
        })
        setSocket(get_conn_socket)
        return get_conn_socket;
    }

    if (!socket && verifyToken() !== undefined) {
        return socket_connection(verifyToken())
    }

    const socketDisconnect = () => {
        if (socket !== undefined) {
            // DISCONNECT USER
            return socket.on('disconnect', (socket) => {
                console.log("Client disconnected")
            })
        } else {
            return null
        }
    }

    return (
        <>
            <SocketContext.Provider
                value={{socket_connection: socket_connection, socketDisconnect: socketDisconnect.call(), socket: socket}}>
                {children}
            </SocketContext.Provider></>
    );
};
import React from 'react';
import $ from 'jquery';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    Link,
    NavLink
  } from "react-router-dom";
import { ReactComponent as Insta } from '../assets/svg/insta.svg';
import { ReactComponent as Fb } from '../assets/svg/fb.svg';

function burgerNav() {

    const targetElement = document.querySelector('.header-nav');
    
    $( '.hamburger' ).toggleClass('is-active');
    $( '.header-nav' ).toggleClass('-open');
    
    if ( $( '.header-nav' ).hasClass( '-open' ) ) {

        $('.header .header-links').appendTo('.header-nav nav ul');

        disableBodyScroll(targetElement);

        $( '.header-nav ul li a' ).on('click', function() {

            $( '.hamburger' ).removeClass('is-active');
            $( '.header-nav' ).removeClass('-open');
            enableBodyScroll(targetElement);

        });

    } else {

        $('.header-nav nav ul .header-links').appendTo('.header .wrapper');

        enableBodyScroll(targetElement);

    }

}

function addClass() {

    $( 'body' ).removeClass( 'bghero' );
    $( 'body' ).removeClass( 'float' );

}

function Header() {

    return (

        <div className="header">

            <div className="wrapper">

                <Link to="/" onClick={addClass} className="header-logo">

                    <Logo />

                </Link>

                <div className="header-nav">

                    <nav>

                        <ul>

                            <li>
                                <NavLink activeClassName="active" onClick={addClass} to="/intrebari-si-propuneri">Întrebări și Propuneri</NavLink>
                            </li>

                            <li>
                                <NavLink activeClassName="active" onClick={addClass} to="/despre-noi">Despre noi</NavLink>
                            </li>

                            <li>
                                <NavLink activeClassName="active" onClick={addClass} to="/blog">Blog</NavLink>
                            </li>

                            <li>
                                <NavLink activeClassName="active" onClick={addClass} to="/evenimente">Evenimente</NavLink>
                            </li>

                            <li>
                                <NavLink activeClassName="active" onClick={addClass} to="/implica-te">Implică-te</NavLink>
                            </li>

                        </ul>

                    </nav>

                </div>

                <div className="header-links">

                    <a href="https://www.instagram.com/tineretbujac/" target="_blank" rel="noopenner noreferrer"><Insta /></a>

                    <a href="https://www.facebook.com/groups/965109080215342" target="_blank" rel="noopenner noreferrer"><Fb /></a>

                </div> 

                <button onClick={burgerNav} className="hamburger header-hamburger hamburger--collapse" type="button">
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>

            </div>

        </div>
    
    )
}

export default Header;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Hero from '../components/Hero';
import heroImg from '../assets/img/isp-main.jpg';
import QuestionsAndProposalsSection from "../components/isp/QuestionAndProposalsSection";

function IntrebariPropuneri() {

    const heroTitle = 'Întrebări și propuneri';
    const heroDescription = 'Ai întrebări fără răspuns...Îți este rușine să întrebi pe cineva...este un subiect taboo...părinții nu te înțeleg...ai nevoie de o confirmare că răspunul tău este bun...etc. Încercăm să te ajutăm în găsirea răspunsului biblic într-una din serile de luni.';

    const [formData, setFormData] = useState({
        category: '61858c2c37f495311709c819',
        description: ''
    });

    const [isp, setIsp] = useState({
        questions: []
    });
    const [isLoading, setIsLoading] = useState(true);

    const { category, description } = formData;

    const { questions } = isp;

    const handleChange = text => e => {
        setFormData({ ...formData, [text]: e.target.value })
    };

    const addQuestion = () => {
        axios.post(`${process.env.REACT_APP_API_URL}add/question`, { category, description }).then(result => {

            setFormData({
                ...formData,
                category: category,
                description: ''
            });

            const current = new Date();
            const date = `${current.getDate()}.${current.getMonth() + 1}.${current.getFullYear()}`;
            const newQuestion = {
                category: category,
                description: description,
                date: date
            };

            setIsp({
                ...isp,
                questions: [...isp.questions, newQuestion]
            });

        }).catch(err => {
            console.log("Error", err)
        })
    };

    useEffect(() => {
        setIsLoading(true);

        axios.get(`${process.env.REACT_APP_API_URL}`)
            .then(function (response) {

                const { questions } = response.data;

                setIsp(
                    {
                        ...isp,
                        questions: questions
                    }
                )

                setIsLoading(false);

            })
            .catch(function (error) {

                console.log(error);

                setIsLoading(false);

            })
    }, []);

    return (
        <div className="isp">

            <Hero side='right' title={heroTitle} description={heroDescription} img={heroImg} />

            <div className="isp-form wrapper">

                    <div className="isp-form-img"></div>

                    <div className="isp-form-background">

                        <div className="isp-form-main">

                            <div className="radio-buttons">

                                <div className="category">

                                    <input type="radio" id="intrebare" name="i" onChange={handleChange('category')} value="61858c2c37f495311709c819" defaultChecked />
                                    <label htmlFor="question">Întrebare</label>

                                </div>

                                <div className="category margin-left">

                                    <input type="radio" id="propunere" name="i" onChange={handleChange('category')} value="61858c893783963f2e4d86da" />

                                    <label htmlFor="proposal">Propunere</label>

                                </div>

                            </div>

                            <div className="isp-input">

                                <textarea id="isp-input" value={description} onChange={handleChange('description')} name='isp' placeholder="Adaugă întrebarea ta aici" required="true" />

                            </div>

                            <div className="small-note">

                                <p>Selectează o întrebare sau propunere în funcție de ceea ce vrei să ne comunici. Acest formular este în totalitate anonim.</p>

                            </div>

                            <div className="isp-button">

                                <button className="btn" onClick={addQuestion} type="submit">Trimite</button>

                            </div>

                        </div>

                    </div>

            </div>

            <div className="isp-intermediate-section wrapper">

                <div className="herotext">

                    <h2 className="title48">Nu ești singurul care ai avut o întrebare sau propunere.</h2>

                    <p className="p20">Întreabă ce ai pe suflet. Simte-te liber, adaugă o propunere ce ți-a venit tocmai acum. Oricare ar fi, ne bucurăm că ai o întrebare sau o propunere. Postarea ta este în totalitate anonimă. În această secțiune nu este important numele tău, ci dorim ca întrebarea / propunerea ta să fie luată în seamă.</p>
                </div>

            </div>

            <QuestionsAndProposalsSection isLoading={isLoading} questionsAndProposals={questions} />

            {/*<div className="wrapper margin-bottom">*/}

            {/*    <a href="https://tineretbujac.000webhostapp.com/" target="_blank" className="link">*/}
            {/*        */}
            {/*        Arhivă Î&P <Arrow />*/}
            {/*        */}
            {/*    </a>*/}

            {/*</div>*/}

        </div>
    );
}

export default IntrebariPropuneri;

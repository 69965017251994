import axios from "axios";
import {authenticate} from "../../helpers/auth";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

export default function AD_Blogs() {

    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}admin/blogs`, {

        }).then(result => {
            setBlogs(result.data)
        }).catch(err => console.log("err", err))

        return blogs
    }, [])

    return(
        <div>
            <h1>Bloguri</h1>

            <Link to="/admin/dashboard">Dashboard</Link>

            <div>
                {blogs.map((blog, i) => {
                    return <div key={i}>{blog.title}</div>
                })}
            </div>

        </div>
    )
}
import React, {useEffect, useState} from "react";
import QuestionsAndProposals from "./QuestionsAndProposals";

function QuestionsAndProposalsSection(props) {
    const isLoading = props.isLoading;
    const questionsAndProposals = props.questionsAndProposals;

    const [activeSection, setActiveSection] = useState('');
    const [sectionData, setSectionData] = useState([]);

    // Group questions into sections by date
    // Each sections starts in the first day of july instead of the first day of the year
    function groupBySection(data) {
        const grouped = {};

        data.forEach(item => {
            // Convert date string to Date object
            const dateParts = item.date.split('.');
            const date = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0])); // year, month, day

            // Determine the section year
            let sectionYear = date.getFullYear();
            if (date.getMonth() < 6) { // January (0) to June (5)
                sectionYear--;
            }

            // Define the section key
            const sectionKey = `${sectionYear}-${sectionYear + 1}`;

            // Initialize the section if it doesn't exist
            if (!grouped[sectionKey]) {
                grouped[sectionKey] = [];
            }

            // Add the item to the correct section
            grouped[sectionKey].push(item);
        });

        // Convert the grouped object to an array of objects with 'name' and 'values'
        return Object.keys(grouped).map(key => ({
            name: key,
            values: grouped[key]
        }))
            // Sorting to ensure the sections are ordered by descending years
            .sort((a, b) => b.name.localeCompare(a.name));
    }

    const groupedData = groupBySection(questionsAndProposals);

    useEffect(() => {
        if (groupedData.length > 0) {
            setSectionData(groupedData[0].values);
            setActiveSection(groupedData[0].name);
        }
    }, [questionsAndProposals]);

    const handleLoadSectionQuestions = (sectionName) => {
        const section = groupedData.find(section => section.name === sectionName);
        setActiveSection(sectionName);
        setSectionData(section.values);
    }

    if (isLoading) {
        return (
            <div className={"sections"}>
                <div className={"selector wrapper"}>
                    <p className={"loadingText"}>Se încarcă întrebările și propunerile...</p>
                </div>
            </div>
        )
    }

    return (
        <div className={"sections"}>
            <div className={"selector wrapper"}>
                {
                    groupedData.map((section, index) => (
                        <div
                            key={index}
                            className={`select ${section.name === activeSection ? 'active' : ''}`}
                            onClick={() => {handleLoadSectionQuestions(section.name)}}
                        >
                            {section.name}
                        </div>
                    ))
                }
            </div>
            <QuestionsAndProposals questions={sectionData} />
        </div>
    )
}

export default QuestionsAndProposalsSection;
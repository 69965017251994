import React from 'react';
import $ from 'jquery';

function HeroEvt(props) {

    $( 'body' ).addClass( 'float' );

    return (

        <div className="heroblog">

            <img src={props.img} alt={props.title} />

            <div className="heroblog-con wrapper">

                <h1 className="title50">{props.title}</h1>

            </div>

        </div> 
    );
}

export default HeroEvt;
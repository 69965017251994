import {useState, useEffect} from "react";
import axios from "axios";
import {getToken} from "../../helpers/auth";

export default function AD_CreateBlog() {

    const [formData, setFormData] = useState({
        categories: [],
        title: "",
        description: ""
    })

    const {categories, title, description, paragraph1, content} = formData

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}admin/blog/categories`, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            }
        }).then(result => {
            setFormData({...formData, categories: result.data})
        }).catch(err => console.log("err", err))
    }, [])

    const createBlog = (e) => {
        e.preventDefault()
        let formData = new FormData();
        formData.append("file", file);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("paragraph1", paragraph1);
        formData.append("content", content);
        formData.append("category", "619947a2105405da02eeb2f8");
        axios.post(`${process.env.REACT_APP_API_URL}admin/create/blog`, formData, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            }
        }).then(result => {
            setFormData(result.data)
        }).catch(err => console.log("err", err))
    }

    const handleChange = text => e => {
        setFormData({...formData, [text]: e.target.value})
    }

    const [file, setFile] = useState();

    const onChange = (file) => {
        if (file) {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.addEventListener("load", (event) => {
                setFile(event.target.result);
            });
            return setFile(file);
        }
    };

    return (
        <div>
            <h1>Adauga blog</h1>

            <form onSubmit={createBlog}>
                <img
                    src={file}
                    alt="Current photo"
                />
                <input
                    type="text"
                    onChange={handleChange('title')}
                    placeholder="Titlu"
                />
                <input
                    type="text"
                    onChange={handleChange('description')}
                    placeholder="Descriere"
                />
                <textarea
                    onChange={handleChange('paragraph1')}
                    placeholder="Paragraph1"
                />
                <textarea
                    onChange={handleChange('content')}
                    placeholder="Content"
                />
                <input
                    type="file"
                    onChange={(e) => {
                        onChange(e.target.files[0]);
                    }}
                    id="files"
                    className="hidden cursor-pointer"
                />
                <button type="submit">
                    Adauga blog
                </button>
            </form>
        </div>
    )
}
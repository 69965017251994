import React from 'react';
import $ from 'jquery';
import './assets/scss/main.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    // Link
} from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';
import IntrebariPropuneri from './pages/IntrebariPropuneri';
import About from './pages/About';
import Blogs from './pages/Blog';
import Events from './pages/Events';
import Blog from './pages/SingleBlog';
import Event from './pages/SingleEvent';
import Formular from './pages/Formular';
import ScrollToTop from './components/scrollToTop';
import JoinChannel from './components/Join-Channel.jsx'
import {SocketProvider} from "./helpers/SocketContext";
import Login from "./pages/admin/Login";
import AdminRoute from "./components/AdminRoute";
import AD_Dashboard from "./pages/admin/AD_Dashboard";
import AD_CreateBlog from "./pages/admin/AD_CreateBlog";
import AD_Blogs from "./pages/admin/AD_Blogs";

function App() {

    return (
        <Router>
            <SocketProvider>
                <ScrollToTop/>
                <Header/>
                <Switch>

                    {/* CLIENT */}
                    <Route path="/" exact render={props => <Homepage{...props}/>}/>
                    <Route path="/evenimente/:slug" exact render={props => <Event{...props}/>}/>
                    <Route path="/blog/:slug" exact render={props => <Blog{...props}/>}/>
                    <Route path="/intrebari-si-propuneri" exact render={props => <IntrebariPropuneri{...props}/>}/>
                    <Route path="/despre-noi" exact render={props => <About{...props}/>}/>
                    <Route path="/blog" exact render={props => <Blogs{...props}/>}/>
                    <Route path="/evenimente" exact render={props => <Events{...props}/>}/>
                    <Route path="/implica-te" exact render={props => <Formular{...props}/>}/>
                    <Route path="/join" exact render={props => <JoinChannel{...props}/>}/>

                    {/*  ADMIN  */}
                    <Route path="/login" exact render={props => <Login{...props}/>}/>
                    <AdminRoute path="/admin/dashboard" exact component={AD_Dashboard}/>
                    <AdminRoute path="/admin/create/blog" exact component={AD_CreateBlog}/>
                    <AdminRoute path="/admin/blogs" exact component={AD_Blogs}/>

                </Switch>
                <Footer/>
            </SocketProvider>
        </Router>
    );
}

export default App;

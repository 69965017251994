import React from 'react';
import $ from 'jquery';

function HeroEvt(props) {

    $( 'body' ).addClass( 'float' );

    return (

        <div className="heroevt">

            <img src={props.img} alt={props.title} />

            <div className="heroevt-con wrapper">

                <h1 className="title50">{props.title}</h1>

                <p className="p18">{props.description}</p>

                <span className="date">{props.date}</span>

            </div>

        </div> 
    );
}

export default HeroEvt;
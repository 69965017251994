import React, {useEffect} from 'react';
import Hero from '../components/Hero';
import heroImg from '../assets/img/bg-implica-te.jpg';
import axios from "axios";

const heroTitle = 'Implică-te la Tineret';

const heroDescription = "Credem că Dumnezeu este Cel care ne cheamă la slujire și ne capacitează pe fiecare dintre noi pentru aceasta. Dacă vrei să faci mai mult pentru Dumnezeu sau simți că ești chemat la o anumită slujire, la tineret ai ocazia să te implici și tu. Completează formularul pentru a-ți începe slujirea.";

function About() {

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}form`)
            .then(function (response) {
            })
    }, [])

    return (

        <div className="aboutpage">

            <Hero side='left' title={heroTitle} description={heroDescription} img={heroImg} />
 
            <div className="aboutpage-formular">

                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf7WHxfJdt6nRvJo5S6seoVRGlZ3jJwloc6jg01IgjDZo9gNA/viewform" width="640" height="650" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>

            </div>

        </div>
    );
}

export default About;
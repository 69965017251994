import React, { useState, useEffect } from 'react';
import {
    Link
} from "react-router-dom";
import {useParams} from "react-router-dom";
import { Gallery, PhotoSwipe } from 'react-pswp';
import 'react-pswp/dist/index.css';
import axios from 'axios';

import Hero from '../components/Hero-evt';
import { ReactComponent as Arrow } from '../assets/svg/arrow.svg';

function SE() {

    const {slug} = useParams();

    const [formData, setFormData] = useState({
        _id: '',
        title: '',
        subTitle: '',
        paragraph1: '',
        paragraph2: '',
        paragraph3: '',
        gallery: [],
        date: '',
        image: '',
        categoryName: ''
    })

    const {_id, title, description, descriptionImg, categoryName, paragraph1, subTitle, paragraph2, paragraph3, gallery, date, image} = formData

    const handleChange = text => e => {
        setFormData({...formData, [text]: e.target.value})
    }

    const [index, setIndex] = useState(null);
    const [open, setOpen] = useState(false);
    
    const container = gallery.map((picture, i) => ({
        uid: i,
        src: gallery[i],
        msrc: gallery[i],
        w: 1000,
        h: 1000,
        title: `Tineret Bujac`,
    }));

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}event/${slug}`)
        .then(function (response) {

            const {_id, title, description, descriptionImg, categoryName, paragraph1, subTitle, paragraph2, paragraph3, gallery, date, image} = response.data;

            setFormData (
                {
                    ...formData, _id, title, description, descriptionImg, categoryName, paragraph1, subTitle, paragraph2, paragraph3, gallery, date, image

                }
            )


        })
        .catch(function (error) {

            console.log(error);

        })

    }, []);

    useEffect(() => {
        if (!open && index !== null) setOpen(true);
    }, [index]);

    return (

        <div className="event" id={_id}>

            <Hero title={title} description={description} date={date} img={image} />

            <div className="event-main wrapper">

                <div className="event-main-content content">

                    <p>{paragraph1}</p>

                    <h2>{subTitle}</h2>

                    <p>{paragraph2}</p>

                    <img src={descriptionImg || image} alt={title}/>

                    <p>{paragraph3}</p>

                    <span className="categorynamehash"># {categoryName}</span>

                    <div className="gallery">
                        
                        <Gallery
                        container={container}
                        onClick={setIndex}
                        wrapperClass="gallery-content"
                        itemClass="gird-gallery-item"
                        imgClass="i"
                        />
                    
                        <PhotoSwipe
                        container={container}
                        onIndexChange={setIndex}
                        onOpenChange={setOpen}
                        index={index}
                        open={open}
                        theme={{
                            foreground: '#b19e6c',
                            background: '#121b3e',
                        }} />

                    </div>

                </div>

                <div className="event-main-backlink">

                    <Link to="/evenimente" className="link"><Arrow /> Mergi înapoi </Link>

                </div>

            </div>

        </div>
    );
}

export default SE;
import {useState, useContext} from "react";
import axios from "axios";
import {authenticate, getToken, isAuth} from "../../helpers/auth";
import { useHistory } from "react-router-dom";
import {SocketContext} from "../../helpers/SocketContext";

export default function Login() {

    let history = useHistory();

    const [credentials, setCredentials] = useState()

    const {socket_connection} = useContext(SocketContext)

    const handleChange = text => e => {
        setCredentials({...credentials, [text]: e.target.value})
    }

    const authentication = e => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_URL}login`, {
            email: credentials?.email,
            password: credentials?.password,
        }).then(result => {
            return authenticate(result.data.token, () => {
                socket_connection(result.data.token)
                history.push('/admin/dashboard')
            })
        }).catch(err => console.log("err", err))
    }

    return (
        <div>
            {getToken() ? history.push('/admin/dashboard') : ""}
            <h1>Logare</h1>

            <form onSubmit={authentication}>
                <div>
                    <input type="email"
                           onChange={handleChange('email')}
                    />
                    <input type="password"
                           onChange={handleChange('password')}
                    />

                    <button type="submit">
                        Logare
                    </button>
                </div>
            </form>
        </div>
    )
}
import cookie from 'js-cookie'
import React from "react";

export const setCookie = (key, value) => {
    if (window !== 'undefiend') {
        cookie.set(key, value, {
            // 30 Days
            expires: 30
        })
    }
}

export const setLocalStorage = (key, value) => {
    if (window !== 'undefined') {
        localStorage.setItem(key, JSON.stringify(value));
    }
};

// GET COOKIE
export const getCookie = key => {
    if (window !== 'undefined') {
        return cookie.get(key);
    }
};

export const authenticate = (token, next) => {
    setCookie('token', token);
    next();
};

export const getToken = () => {
    const token = getCookie('token');
    if (token) {
        return token
    }
}

// IS AUTH
export const isAuth = () => {
    if (window !== 'undefined') {
        const token = getCookie('token');
        if (token) {
            return token;
        }
    }
};
import React from "react";

function QuestionsAndProposals(props) {

    const questions = props.questions;

    let item_middle = 1;
    const isMarginItem = (index) => {

        if (index === item_middle) {
            item_middle += 3;
            return 'item-middle';
        }

        return '';
    };

    let item_right = 2;
    const isRightItem = (index) => {

        if (index === item_right) {
            item_right += 3;
            return 'item-right';
        }

        return '';
    }

    const addCategoryClass = (category) => {

        if (category === 'Intrebare')
            return 'question';

        if (category === 'Propunere')
            return 'proposal';

        return '';
    }

    const getCategoryName = (category) => {
        if (category == '61858c2c37f495311709c819') {
            return 'Intrebare';
        }
        
        if (category == '61858c893783963f2e4d86da') {
            return 'Propunere';
        }

        return category;
    }

    return (
        <div className="isp-items wrapper">

            {Object.keys(questions).map((qs, i) => (

                <div className={`item ${isMarginItem(i)} ${isRightItem(i)}`} key={i}>

                    <div className={`${addCategoryClass(getCategoryName(questions[qs].category))}`}>

                        <div className="category">
                            <span>{getCategoryName(questions[qs].category)}</span>
                        </div>

                        <div className="big-card">

                            <div className="description">
                                {questions[qs].description}
                            </div>

                            <div className="date">
                                {questions[qs].date}
                            </div>

                        </div>

                    </div>

                </div>

            ))}

        </div>
    );

}

export default QuestionsAndProposals;
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import {
    Link
} from "react-router-dom";
import {useParams} from "react-router-dom";
import { Gallery, PhotoSwipe } from 'react-pswp';
import 'react-pswp/dist/index.css';
import Hero from '../components/Hero-blog';
import axios from 'axios';
import "../index.css"

import { ReactComponent as Arrow } from '../assets/svg/arrow.svg';

import {ReactComponent as Category} from '../assets/svg/grid.svg';
import {ReactComponent as Date} from '../assets/svg/calendar.svg';

function Blog() {

    $( 'body' ).addClass( 'float' );
    $( 'body' ).removeClass( 'bghero' );

    const {slug} = useParams();

    const [formData, setFormData] = useState({
        _id: '',
        title: '',
        paragraph1: '',
        subTitle: '',
        paragraph2: '',
        gallery: [],
        date: '',
        categoryName: ''
    })

    const {_id, title, categoryName, content, paragraph1, subTitle, paragraph2, gallery, date, image, description} = formData

    const handleChange = text => e => {
        setFormData({...formData, [text]: e.target.value})
    }

    const [index, setIndex] = useState(null);
    const [open, setOpen] = useState(false);
    
    const container = gallery.map((picture, i) => ({
        uid: i,
        src: gallery[i],
        msrc: gallery[i],
        w: 1000,
        h: 1000,
        title: `Tineret Bujac`,
    }));

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}blog/${slug}`)
        .then(function (response) {

            const {_id, formData, title, content, categoryName, paragraph1, subTitle, paragraph2, gallery, date, image, description} = response.data;

            setFormData (
                {
                    ...formData, _id, title, content, categoryName, paragraph1, subTitle, paragraph2, gallery, date, image, description

                }
            )


        })
        .catch(function (error) {

            console.log(error);

        })

    }, []);

    useEffect(() => {
        if (!open && index !== null) setOpen(true);
    }, [index]);

    return (

        <div className="blog" id={_id}>

            <Hero title={title} img={image} />
            
            <div className="blog-main wrapper">

                <div className="blog-main-header">

                    <div className="blog-main-header-info">

                        <div className="blog-main-header-info-i">

                            <Category />

                            <span className="p20">{categoryName}</span>

                        </div>

                        <div className="blog-main-header-info-i">

                            <Date />

                            <span className="p20">{date}</span>

                        </div>

                    </div>

                    <p>{description}</p>

                </div>

                <div className="blog-main-content content">

                    <div className="blog-content" dangerouslySetInnerHTML={{__html: content}}/>

                    <div className="gallery">
                        
                        <Gallery
                        container={container}
                        onClick={setIndex}
                        wrapperClass="gallery-content"
                        itemClass="gird-gallery-item"
                        imgClass="i"
                        />
                    
                        <PhotoSwipe
                        container={container}
                        onIndexChange={setIndex}
                        onOpenChange={setOpen}
                        index={index}
                        open={open}
                        theme={{
                            foreground: '#b19e6c',
                            background: '#121b3e',
                        }} />

                    </div>

                </div>

                <div className="blog-main-backlink">

                    <Link to="/blog" className="link"><Arrow /> Mergi înapoi la mai multe blog-uri</Link>

                </div>

            </div>

        </div>
    );
}

export default Blog;
import axios from "axios";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

export default function AD_Dashboard() {

    const [formData, setFormData] = useState({
        categories: 0,
        questions: 0
    })

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}admin/dashboard`, {

        }).then(result => {
            setFormData(result.data)
        }).catch(err => console.log("err", err))

        return formData
    }, [])

    return(
        <div>
            <h1>Dashboard</h1>

            <h3>{formData.categories}</h3>
            <h3>{formData.questions}</h3>

            <Link to="/admin/create/blog">Adauga blog</Link>
        </div>
    )
}
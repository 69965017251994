// import {SocketContext} from "../helpers/SocketContext";
// import {useContext, useEffect} from "react";
// import socketio from "socket.io-client";

export default function JoinChannel() {

    return(
        <div>
            <h1>Join Channel</h1>
        </div>
    )
}
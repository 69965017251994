import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import {
    Link
} from "react-router-dom";
import { ReactComponent as Arrow } from '../assets/svg/arrow.svg';

function Blogs() {

    $( 'body' ).removeClass( 'float' );

    $( 'body' ).addClass( 'bghero' );

    const [formData, setFormData] = useState({
        blogs: [],
        categories: [],
        last_blog: {}
    });

    const {blogs, categories, last_blog} = formData;

    const getBlogs = (category_id) => {

        axios.get(`${process.env.REACT_APP_API_URL}blogs/category/${category_id}`)
        .then(function (response) {

            const {blogs, categories, last_blog} = response.data;

            setFormData (
                {
                    ...formData, blogs, categories, last_blog

                }
            )


        })
        .catch(function (error) {

            console.log(error);

        })

    }

    useEffect(() => {

        $(document).on('click', '.filtersbtns-i', function() {

            $( '.filtersbtns-i' ).removeClass( 'active' );

            $(this).addClass('active');

        });
        
        axios.get(`${process.env.REACT_APP_API_URL}blogs`)
        .then(function (response) {

            const {formData, blogs, categories, last_blog} = response.data;

            setFormData (
                {
                    ...formData, blogs, categories, last_blog

                }
            )


        })
        .catch(function (error) {

            console.log(error);

        })

    }, []);

    const [category, setCategory] = useState();

    return (

        <div className="blogs" data-used={[category, setCategory]}>

            <div className="blogs-header">

                <h1 className="title60">Blog</h1>

                <p className="p20">Într-o lume cu atâtea informații, vrem să scoatem balastul și să șlefuim mărgăritarul. Informațiile de aici dorim să te ajute în luarea deciziilor potrivite pentru viața ta de zi cu zi.</p>

            </div>

            <div className="filtersbtns wrapper">

                <button className="filtersbtns-i active" onClick={() => { getBlogs('all'); }}>Toate Categoriile</button>

                {Object.keys(categories).map((qs, i) => (

                    <button onClick={() => { getBlogs( categories[qs]._id ); }} className="filtersbtns-i" key={i} data-categories={categories[qs].name}>{categories[qs].name}</button>

                ))}

            </div>

            <div className="blogs-items wrapper">

                <div className="blogs-items-fi">

                    <div className="blogs-items-fi-img">

                        <img src={last_blog.image} alt={last_blog.title} />

                    </div>

                    <div className="blogs-items-fi-con">

                        <h2 className="title50">{last_blog.title}</h2>

                        <div className="blogs-items-fi-con-infos">
                            
                            <span>{last_blog.categoryName}</span>

                            <span>{last_blog.date}</span>

                        </div>

                        <p className="p20">{last_blog.description}</p>

                        <Link to={`blog/${last_blog.slug}`} className="link">Citește mai departe <Arrow /></Link>
                        

                    </div>

                </div>

                {Object.keys(blogs).map((qs, i) => (

                    <div className="articledesign" data-personal-cat={blogs[qs].categoryName} key={i}>

                        <div className="articledesign-img">

                            <Link to={`blog/${blogs[qs].slug}`}>

                                <img src={blogs[qs].image} alt={blogs[qs].title} />

                            </Link>

                        </div>

                        <Link to={`blog/${blogs[qs].slug}`} className="articledesign-con">

                            <h3>{blogs[qs].title}</h3>

                            <p>{blogs[qs].description}</p>

                            <span className="link">Citește mai departe <Arrow /></span>

                        </Link>

                    </div>

                ))}

            </div>

        </div>
    );
}

export default Blogs;